import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';

import Layout from '../components/Layout';
import Features from '../components/Features';
import Contact from '../components/Contact';

export const IndexPageTemplate = ({
  image,
  title,
  logo,
  banner,
  heading,
  subheading,
  intro,
  contact,
}) => (
  <div>
    <div
      className='full-width-image margin-top-0'
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `top left`,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '0',
        }}
        className='has-text-centered'
      >
        <h1
          className='is-size-5-mobile is-size-5-tablet is-size-4-widescreen'
          style={{
            color: '#428dff',
            margin: '1% 0 2% 0',
          }}
        >
          {title}
        </h1>
        <img
          data-sal='zoom-in'
          data-sal-delay='300'
          src={!!logo.childImageSharp ? logo.childImageSharp.fluid.src : logo}
          alt='devoom'
          style={{ width: '55%', margin: '0 0 2% 0' }}
        />
        <h3
          className='has-text-weight-bold heading-title'
          style={{
            color: '#333333',
            margin: '0 0 1% 0',
          }}
        >
          {heading}
        </h3>
        <h3
          className='has-text-weight-light subheading-title'
          style={{
            color: '#333333',
          }}
        >
          {subheading}
        </h3>
        <br />
        <div className='btn-top-panel'>
          <Link
            className='button btn-top'
            style={{
              margin: '2%',
              backgroundColor: '#f7b900',
              color: 'white',
            }}
            to='/#prestations'
          >
            EN SAVOIR PLUS
          </Link>
          <Link
            className='button btn-top is-primary'
            style={{ margin: '2%' }}
            to='/#contact'
          >
            CONTACT
          </Link>
        </div>
      </div>
    </div>
    <section
      className='section section--gradient'
      style={{ marginBottom: '0' }}
    >
      <div className='container'>
        <div className='section'>
          <h3 className='is-size-2 has-text-centered title' id='prestations'>
            {intro.title}
          </h3>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='content'>
                <Features gridItems={intro.blurbs} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div
        className='full-width-banner margin-top-0'
        style={{
          backgroundImage: `url(${
            !!banner.childImageSharp ? banner.childImageSharp.fluid.src : banner
          })`,
          backgroundPosition: `center`,
          backgroundAttachment: 'fixed',
        }}
      >
        <svg
          className='svg-banner-top'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 100 100'
          preserveAspectRatio='none'
        >
          <polygon fill='white' points='0,0 100,0 80,100' />
        </svg>
      </div>

      <div style={{ position: 'relative', width: '100%', bottom: '100px' }}>
        <svg
          className='svg-banner-bottom'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 100 100'
          preserveAspectRatio='none'
        >
          <polygon fill='white' points='0,20 0,100 100,100' />
        </svg>
        <svg
          className='svg-banner-bottom'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 100 100'
          preserveAspectRatio='none'
          style={{ opacity: '0.5', position: 'absolute', left: '0' }}
        >
          <polygon fill='white' points='0,20 0,100 100,100 100,50' />
        </svg>
        <svg
          className='svg-banner-bottom'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 100 100'
          preserveAspectRatio='none'
          style={{ opacity: '0.25', position: 'absolute', left: '0' }}
        >
          <polygon fill='white' points='0,20 0,100 100,100 100,0' />
        </svg>
      </div>
    </section>
    <h3 className='is-size-2 has-text-centered title' id='contact'>
      {contact.title}
    </h3>
    <section className='section'>
      <div className='columns'>
        <div className='column is-12 has-text-centered has-text-left-mobile'>
          <Contact />
        </div>
      </div>
    </section>
  </div>
);

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  banner: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  intro: PropTypes.shape({
    title: PropTypes.string,
    blurbs: PropTypes.array,
  }),
  contact: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        logo={frontmatter.logo}
        banner={frontmatter.banner}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        intro={frontmatter.intro}
        contact={frontmatter.contact}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logo {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        banner {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        intro {
          title
          blurbs {
            icon
            animation
            text
          }
        }
        contact {
          title
          description
        }
      }
    }
  }
`;

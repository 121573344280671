import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FeatureGrid = ({ gridItems }) => (
  <div className='columns is-multiline'>
    {gridItems.map(item => (
      <div key={item.text} className='column is-6'>
        <section
          data-sal={item.animation}
          data-sal-delay='200'
          className='section'
        >
          <div className='has-text-centered'>
            <div
              style={{
                display: 'inline-block',
                marginBottom: '10px',
              }}
            >
              <FontAwesomeIcon
                icon={item.icon}
                style={{
                  color: 'white',
                  fontSize: '24px',
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%',
                  background: '#f7b900',
                  padding: '20px',
                  fontWeight: 'lighter',
                }}
              />
            </div>
          </div>
          <h3
            className='has-text-centered is-size-4'
            style={{ fontWeight: 'normal' }}
          >
            {item.text}
          </h3>
        </section>
      </div>
    ))}
  </div>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      animation: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

export default FeatureGrid;

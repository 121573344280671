import React from 'react';
import { navigateTo } from 'gatsby-link';
import Recaptcha from 'react-google-recaptcha';

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRecaptcha = value => {
    this.setState({ 'g-recaptcha-response': value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigateTo(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  render() {
    return (
      <form
        name='contact-recaptcha'
        method='post'
        action='/thanks/'
        data-netlify='true'
        data-netlify-recaptcha='true'
        onSubmit={this.handleSubmit}
      >
        <noscript>
          <p>Ce formulaire ne fonctionnera pas si Javascript est désactivé</p>
        </noscript>
        <div className='field is-horizontal'>
          <div className='field-label is-normal'>
            <label className='label'>Nom</label>
          </div>
          <div className='field-body'>
            <div className='field'>
              <p className='control'>
                <input
                  className='input'
                  type='text'
                  name='name'
                  placeholder='Nom'
                  onChange={this.handleChange}
                  required={true}
                />
              </p>
            </div>
          </div>
        </div>
        <div className='field is-horizontal'>
          <div className='field-label is-normal'>
            <label className='label'>Adresse email</label>
          </div>
          <div className='field-body'>
            <div className='field'>
              <p className='control'>
                <input
                  className='input'
                  type='email'
                  name='email'
                  placeholder='Adresse email'
                  onChange={this.handleChange}
                  required={true}
                />
              </p>
            </div>
          </div>
        </div>
        <div className='field is-horizontal'>
          <div className='field-label is-normal'>
            <label className='label'>Message</label>
          </div>
          <div className='field-body'>
            <div className='field'>
              <p className='control'>
                <textarea
                  className='textarea'
                  placeholder='Message'
                  name='message'
                  onChange={this.handleChange}
                  required={true}
                ></textarea>
              </p>
            </div>
          </div>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Recaptcha
            ref='recaptcha'
            sitekey={RECAPTCHA_KEY}
            onChange={this.handleRecaptcha}
          />
          <br />
          <button className='button is-primary' type='submit'>
            Envoyer
          </button>
        </div>
      </form>
    );
  }
}

export default Contact;
